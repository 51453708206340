footer {
  border-top: 12px solid #f5b230;
  border-bottom: 12px solid #2c87c6;
  display: flex;
  align-content: center;
  justify-content: center;
}
footer section.social {
  min-width: 340px;
  margin:0;
}
footer .footer-content {
  display: flex;
}
@media only screen and (max-width: 1000px) {
  footer .footer-content {
    flex-direction: column;
  }
}
footer section {
  margin: 20px;
}
footer section.sitemap {
  min-width: 44%;
}
  footer ul {
    padding:0;
  }
  footer ul li:first-child:before {
  content: "";
  margin: 0;
}
footer ul li:before {
  content: "|";
  margin:0 8px;
}
footer ul li {
  list-style-type: none;
  display: inline-block;
}
footer ul li a:hover {
  font-weight: bold;
}
footer ul li a {
  color: #000;
}