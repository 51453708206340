form input:focus,
form textarea:focus
{
    border-color: #f5b230;
    outline: none;
}
form input,
form textarea {
    line-height: 25px;
    background: white;
    font-size: 17px;
    color: black;
    border-width: 2px;
    margin: 12px 0  ;
    border-width: 2px;
    border-style: solid;  
    height: 35px;
    width: 100%;
    padding: 5px 10px;
    box-sizing: border-box;
}
form input:focus::-webkit-input-placeholder {
    color: rgb(224, 224, 224);    
}
form input::-webkit-input-placeholder {
    color: rgb(73, 73, 73);
}

form textarea {
    min-height: 150px;
}
.social-medial {
    width: 100%;
}
.contact-page {
    display: flex;
    padding-top:10px;
    align-items: center;
    margin: 0 auto;
    max-width: 85%; 
    margin-top:60px;
}
.contact-forms {
    min-width: 60%;
    margin-right: 25px;
}
.contact-form-type {
    margin-bottom: 8px;
}
.personal-contact img {
    border: 12px solid #2c87c6;
}
.personal-contact {
    font-size: 16px;
    font-weight: 100;
}
.contact-form-type label input {
    margin-right: 8px;
}
.contact-form-type label {
    margin: 15px;
    font-size: 18px;
    text-transform: uppercase;
}
.react-datepicker__input-container {
    width: 70%;
}
.contact-page .villa-field label{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
}
.contact-page .villa-field label input{
    width: auto; 
    margin: 12px;
    display: inline-block;
    font-size: 21px;
}
@media only screen and (max-width: 1000px) {
    
.contact-form-type label {
    font-size: 15px;
    }

.contact-form-type {
    margin-right: 46px;
    }
.contact-page{
    flex-direction: column;
}
.personal-contact img {
    max-width: 95%;
}
h1 {
    text-align: center;
}

}