nav {
  background: rgba(255,255,255,.9);
  border-top: solid 8px #f5b230;
  width: 100%;
  position: fixed;  
  z-index: 100;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.34);  
}
nav ul {
  margin:0;
  display: inline-block;
}
nav ul li:first-child {
  border:none;
}
nav ul li {
  display: inline;
  padding: 15px 25px;
  line-height: 54px;  /* not sure if needed */
  font-size: 18px;
  border-left: rgba(0,0,0,.86) solid 1px;
  font-family: 'Playfair Display', serif;
}
nav a:hover {
  text-decoration: underline;
}
nav a.active {
  font-weight: bold;
}
nav a {
  color: rgba(0,0,0,1);
  text-decoration: none;
  text-transform: uppercase;
}
nav .brand {
  font-size: 22px;
  font-family: 'Playfair Display', serif;  
  margin-left: 25px;
  display: inline-block;  
  text-transform: uppercase;
}
.button:hover {
  border-bottom: 3px solid #1D5D88;
  border-right: 3px solid #1D5D88;
  background:rgb(86, 107, 121);
}
.button {
  font-size: 16px;
  transition: all .3s ease; 
   height: 40px;
   text-transform: uppercase;
   border: none;  
   border-radius: 4px;
   border-bottom: 3px solid #1D5D88;
   border-right: 3px solid #1D5D88;
   font-weight: 500;
   color: #fff;
   padding: 6px 30px;
   background: #2b87c6;
   display: inline-block;
   line-height: 26px;
   margin: 15px 0;
   text-decoration: none;
   box-sizing: border-box;
   cursor: pointer;
}
.button.yellow:hover {
  border-bottom: 3px solid #b99040;
  border-right: 3px solid #b99040;
  background: #ffac07;
}
.button.yellow {
  background-color: #f5b230;
  border-bottom: 3px solid #b99040;
  border-right: 3px solid #b99040;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;  
}
p {
  line-height: 28px;
}
h1, h2, h3 {
  font-family: 'Playfair Display', serif;
  color: rgba(0,0,0,.86);
}
h1 {
  font-size: 36px;
  line-height: 42px;
}
h1, h2 {
  text-transform: uppercase;
}
.content {
  max-width: 80%;
  min-width: 60%;
  margin: auto;
  font-size: 18px;
  line-height: 24px;
}
.mobileMenu {
  display: none;

}
@media only screen and (max-width: 1000px) {
  h1 {
    font-size: 31px;
    line-height: 33px;
  }
  nav {
    min-height: 50px;
  }
  nav .hamburger {
    display: block;
  }
  nav .brand {
    margin-top: 12px;
  }
  nav ul {
    display: block;
  }
  nav ul li {
    display: block;
    padding: 10px 25px;
    line-height: 22px;
    font-size: 16px;
  }
  .mobileMenu {
    display: block;
    float: right;
    margin: 15px 15px 0px 0px; 
  }
  nav ul {
    display: none;
  }
  nav.menu-open ul {
    padding: 0;
    display: block;
  }
  footer {
    display: none;
  }
  .button{
    font-size: 13px;
    height: 35px;
  }
}