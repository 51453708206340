.chateau-list-view.pull-up {
    margin-top: -35px;
}
.chateau-list-view {
    background: url('/images/chateau-bg.png');
    background-size: cover;
    min-height: 60vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    align-items: center;
    z-index: 2
}
.chateau-intro {
    padding: 15px;  
    line-height: 32px;
}

.list.full-width .chateau-item {
    max-width: 28%;
}
.list {
    display: flex;
    flex-direction: row;
    min-width: 67%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
}

.chateau-item:hover {
    box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
}
.chateau-item {
    min-width: 250px;
    margin: 20px;
    max-width: 45%;
    transition: all 0.3s linear 0s;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}
.chateau-item h3 {
    color: rgba(255,255,255,0.7);
    z-index: 292563;
    padding: 5px 0 2px 8px;
    text-transform: uppercase;
    margin:0;
}
.chateau-item figure:hover .caption {
    background: rgba(0,0,0,0.9);
}
.chateau-item figure:hover .caption p {
    opacity: 1;
    height: auto;
    color: rgba(255,255,255,0.7);
}
.chateau-item .caption p {
    color: rgba(255,255,255,0.0);
    margin:0 0 8px 0;
    font-size: 14px;
    text-align: left;
    padding: 0 8px;
    transition: color .4s ease;
    height: 0;
    opacity: 0;
}
.chateau-item .caption {
    transition: all .4s ease;
    background: rgba(0,0,0,0.7);
    position: absolute;
    bottom: 0px;
    margin:0;
    width: 100%;
}
.chateau-item figure {
    position: relative;
    overflow: hidden;
    margin:0;
}
.chateau-item img {
    max-width: 100%;
}


.chateau-page h1 {
    font-size: 48px;
    color: #fff;
    text-shadow: 3px 3px 10px rgba(0,0,0,.6);
}
.chateau-page {
    min-height: 68vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    padding: 0 20px;
}
@media only screen and (max-width: 1000px) {
    .chateau-page h1 { 
        font-size: 44px;
        line-height: 46px;
    }
    .chateau-list-view.pull-up {
    margin-top: 0;
    flex-direction: column-reverse;
    }
    .list {
        padding: 0;
    }

}