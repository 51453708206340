header figure {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  margin: 0 0 8PX 0;
}
header figure h1 span {
  font-size: 72px;
  letter-spacing: 3.4px;
}
header figure img {
  order: 1;
  width: 26%;
  max-width: 100%;
  margin-right: 15px; 
  margin-left: 15px;
  padding: 0;
}
header figure h1 {
  font-size: 56px;
  letter-spacing: 4.4px;
  font-weight: 400;
  margin: 0 10px;
  order: 2;
  text-transform: uppercase;
  flex-basis: 170px;
}

section.image-intro {
  display: flex;
  /* max-height: 100vh; */
  flex-direction: column;  
  justify-content: center;
  overflow: hidden;
  align-items: center;
}
section.image-intro .image-gallery {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index:0;
  /* max-height: 90vh; */
}

.blur .image-gallery-slide-wrapper {
  width: 100vw;
}
header p {
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
}
header ul {
  display:flex;
  flex-direction: row;
  padding: 0;
  margin: 8px 0 0 0;
  justify-content: space-around;
}
header ul li {
  list-style-type: none;
  font-size: 19px;
  font-weight: 200;
  text-align: center;
  margin: 8px;
}

header.hidden ul, header.hidden p { 
  display:none;
}
header.hidden {
  /* margin-top: 80vh; */
  opacity:0;
  display: none;
}
header {
  transition: opacity .3s ease-in-out;
  background: rgba(255, 255, 255, 1);
  padding: 28px;
  opacity:1;
  position: relative;
  border-radius: 4px;
  z-index: 10;
  flex-direction: column;
  display: flex;
  max-width: 45%;
  margin-top: 35px;
  flex-basis: 43%;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.34);
}
@media only screen and (max-width: 1000px) {
  header ul li {
    font-size: 15px;
    font-weight: 500;
  }
  .image-intro .play{
    display: none;
  }
  .close {
    display: none;
  }
  header figure {
    margin: 0;
  }
  header figure h1 span {
    font-size: 50px;
    letter-spacing: 0px;
  }
  header figure h1 {
    font-size: 33px;
    letter-spacing: 3px;
  }
  section.image-intro .blur .image-gallery {
    display: none;
  }
  section.image-intro .image-gallery {
    margin-top: 50px;
    position: relative;
    background: white;  
  }
  
  section.image-intro {
    background: url('/images/intro-bg.jpg');
    background-size: cover;
    background-position: center;
    max-height: auto;
  }
  header {
    margin-top: 66px;
    margin-bottom: 20px;
    flex-basis: 93%;
    max-width: 90%;
    padding: 10px;
    font-size: 16px;
    line-height: 1.25em;
    text-align: justify;
    letter-spacing: 0.5px;
  }

}
hr {
  margin:12px 5px;
  border: .5px solid;
  border-color: rgba(0,0,0,.57);
}

.container {
  display: flex;
  flex-direction: column;  
}

.image-intro .close:hover {
  transform: scale(1.36);
}

.image-intro i {
  z-index: 100;
}

.image-intro .close {
  transition: all .3s ease;
  position: absolute;
  font-size: 27px;
  font-weight: 200;
  right: 0;
  color: rgba(0,0,0, .67);
  top: 0;
  padding: 20px;
}
.image-intro i.play.hidden {
  opacity:0;
  display:none;
}
.image-intro i.play:hover {
  color: rgba(255,255,255,.86);
  
}
.image-intro i.play {
  font-size: 86px;
  color: rgba(255,255,255,.5);
  transition: all .3s ease-in-out;
  margin: 25px;
  position: relative;
  margin-top: 35px;
  text-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.34); 
}
section.image-intro .focus {
  width: 100%;
}
section.image-intro .focus .image-gallery {
  position: relative;
}


