footer {
  border-top: 12px solid #f5b230;
  border-bottom: 12px solid #2c87c6;
  display: flex;
  align-content: center;
  justify-content: center;
}
footer section.social {
  min-width: 340px;
  margin:0;
}
footer .footer-content {
  display: flex;
}
@media only screen and (max-width: 1000px) {
  footer .footer-content {
    flex-direction: column;
  }
}
footer section {
  margin: 20px;
}
footer section.sitemap {
  min-width: 44%;
}
  footer ul {
    padding:0;
  }
  footer ul li:first-child:before {
  content: "";
  margin: 0;
}
footer ul li:before {
  content: "|";
  margin:0 8px;
}
footer ul li {
  list-style-type: none;
  display: inline-block;
}
footer ul li a:hover {
  font-weight: bold;
}
footer ul li a {
  color: #000;
}
.villa-compact-view span.prijs span {
    font-size: 17px;
}
.villa-compact-view span.prijs {
    font-size: 28px;
    margin-left: 8px;
}
.villa-compact-view .button{
    align-self: right;
}	
.villa-compact-view {
    background: url('/images/villas-bg.jpg');
    background-size: cover;
    background-position: center;
    min-height: 60vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.villa-compact-view .react-tabs .react-tabs__tab-panel {
    background: #fff;
    box-shadow: 10px 10px 63px 0px rgba(0,0,0,0.45);
    padding: 20px;
    z-index: 1;
    position: relative;
    font-family: 'Montserrat', sans-serif;
    line-height: 32px;
    border-radius: 0;
}
.villa-compact-view .react-tabs .react-tabs__tab-list {
    margin: 0;
}
.villa-compact-view .react-tabs {
    width: 900px;
    margin: 100px auto;
}
.villa-compact-view .react-tabs .react-tabs__tab.react-tabs__tab--selected {
    position: relative;
    z-index: 2;
    opacity: 1;
    color: rgba(0,0,0,.87);
    background: white;
}
.villa-compact-view .react-tabs .react-tabs__tab {
    height: auto;
    transition: all .6s ease;
    box-shadow: 6px -11px 28px -4px rgba(0,0,0,0.45);
    color: white;
    font-size: 24px;
    padding: 10px 30px;
    background: #323230;
    font-family: 'Playfair Display', serif;
    text-transform: uppercase;
    border-radius:0;
    border: none;
}
.villa-compact-view .react-tabs__tab .villa-actions {
    display: flex;
    flex-direction: row;
}
section.villa-page-tabs {
    padding: 0 5%;
}
.villa-page-tabs .react-tabs__tab.react-tabs__tab--selected {
    background: #f5b230;
    border: none;
    border-radius: 0;
}
.villa-page-tabs .react-tabs__tab-list {
    padding-bottom: 1px;
    border-bottom: 2px solid #f5b230;
}
.villa-page-tabs .react-tabs__tab {
    border: none;
    background: #f2daab;
    text-transform: uppercase;
    color: #fff;
    padding: 14px 40px;
    font-size: 18px;
    margin: 0 7px 0 0;
}
.villa-page-tabs h1 {
    font-size: 48px;
    margin: 45px 8px 25px 8px;
}
.villa-content {
  display: flex;
  flex-direction: row;
}
.villa-content aside h1,
.villa-content aside h2,
.villa-content aside h3,
.villa-content aside p,
.villa-content hr,
.villa-content aside li {
  color: #fff;
  font-weight: 100;
  border-color: #fff;
}
.villa-content aside {
  background: #323331;
  color: #fff;
  box-sizing: border-box;
  padding: 15px;
  line-height: 28px;
}
.villa-content .left-content {
  width: 80%;
}
.villa-content aside.right-content {
  color: #fff;
  width: 390px;
}
.price-calendar {
  position: relative;
  width: 90%;
}
table.calendar {
  border-collapse: collapse;
  width: 100%;
}

table.calendar thead {
  background-color: #323331;
  color: white;
  margin-bottom: 3px;
  border-bottom: 2px solid white
}


table.calendar thead th {
  font-weight: normal;
  padding: 10px 3px;
}

table.calendar thead th.bolder {
  font-weight: bold;
}

table.calendar tbody {
  font-size: 0.8em;
}

table.calendar td {
  text-align: center;
  padding: 8px;
  cursor: pointer;
  border: 1px solid rgba(185, 185, 185, 0.13);
  background-color: white;
  min-width: 15px;
}

table.calendar tr:last-child td {
  border-bottom: none;
}

table.calendar td.month-name {
  font-weight: bold;
  text-align: left;
  cursor: default;
  border-left: none;
}

table.calendar td.prev-month,
table.calendar td.next-month {
  color: transparent;
  cursor: default;
  pointer-events: none;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=');
}

table.calendar td.week-separator {
  pointer-events: none;
  padding: 0;
  width: 8px;
  min-width: 0;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=');
}

table.calendar td.bolder {
  font-weight: bold;
}

/* Single selected day */
table.calendar td.selected {
  background-color: #b22b26;
  color: white;
  font-weight: bold;
}

/* Selected range */
table.calendar td.range {
  background-color: #2b87c6;
  font-weight: bold;
  color: white;
}

table.calendar td.range-left {
  /* border-top-left-radius: 15px;
    border-bottom-left-radius: 15px; */
    overflow: hidden;
    background: #2b87c6;
  }
  
  table.calendar td.range-right {
    /* border-top-right-radius: 15px;
    border-bottom-right-radius: 15px; */
    overflow: hidden;
    background: #2b87c6;
  }
  
  div.calendar-controls {
    margin: 5px auto;
    display: table;
    font-size: 25px;
    line-height: 35px;
  }
  
  div.calendar-controls div {
    display: inline;
  }
  
  div.calendar-controls .current-year {
    margin: 0 30px;
  }
  
  div.calendar-controls .control {
    font-weight: bolder;
    color: #323232;
    font-size: 0.8em;
    cursor: pointer;
  }
  
  div.calendar-controls .today {
    position: absolute;
    right: 15px;
    line-height: 35px;
    font-size: 0.6em;
    text-transform: uppercase;
  }
  
  /* Demo */
  
  div#calendar {
    position: relative;
    border-radius: 5px;
    border: 1px solid #5A5A5A;
    background-color: white;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  section.prijzen {
    background: url('/images/uitzicht-bg.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .calendar-container {
    max-width: 100%;
    margin:0;
    overflow-x: auto;
  }
  table.calendar td.lastminute {
    background: #f7cf81;
    font-weight: bold;
    border: 2px dashed #f3a921;
    color: rgb(211, 16, 16);

  }
  table.calendar td.blocked.range-right.changedayStart {
    background-color: #2b87c6;
  }
  table.calendar td.blocked.range-left.changedayEnd {
    background-color: #2b87c6;
  }
  table.calendar td.blocked.changedayEnd {
    background-image: url('/images/change-day.svg') !important;
    background-size: cover;
    background-color: white;
    border:none;
  }
  table.calendar td.blocked.changedayStart {
    background-image: url('/images/change-day-2.svg') !important;
    background-size: cover;
    background-color: white;
    border:none;
  }
  table.calendar td.blocked {
    background: orangered;
    border: none;
  }
  aside.legenda {
    background: white;
    padding: 5px;
    margin-top: -10px;
    margin-right: -10px;
    line-height: 25px;
  }
  .legenda-item.lastminute {
    color: rgb(211, 16, 16);
  }
  .legenda-item.blocked:before {
    background-color: orangered
  }
  .legenda-item.lastminute:before {
    background: #f7cf81;
    font-weight: bold;
    border: 2px dashed #f3a921;
  }
  .legenda-item {
    margin-left: 50px;
  }
  .legenda-item.available:before {
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=');
  }
  .legenda-item:before {
    content: ' ';
    position: relative;
    left: -15px;
    top: 1px;
    height: 15px;
    width: 15px;
    background: white;
    display: inline-block;
    box-shadow: 0 3px 14px rgba(0,0,0,.46);
  }
section.prijs-indicatie h2 {
  margin-top: 5px;
  color: white; 
}
section.prijs-indicatie p {
  color: rgba(255,255,255,.86);
}
section.prijs-indicatie {
  background: #323331;
  padding: 10px;
}
ul.prices {
  display: flex;
  width: 100%;
  padding:0;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.prices li.prijs .prijs-tot span,
.prices li.prijs .prijs-vanaf span {
  font-weight: bold;
}
.prices li.prijs .prijs-vanaf {
  padding: 6px 24px 6px 6px;
  background: rgba(0,0,0,.046);
  margin: 8px 0 0 0;
}
.prices li.prijs .prijs-price h3 {
text-transform: uppercase;
}
.prices li.prijs .prijs-tot {
  padding: 6px 24px 6px 6px;
  border-top: 1px solid rgba(0,0,0,.146);
  background: rgba(0,0,0,.046);
  margin: 0 0 8px 0;
}
.prices li.prijs h3 {
  margin: 0 0 8px 0;
  text-transform: uppercase;
}
.prices li.prijs h3 i {
  padding: 0 5px;
}
.prices li.prijs {
  display: flex;
  padding: 10px;
  background: white;
  box-shadow: 2px 0 12px rgba(0,0,0, 0.8);
  width: 90%;
  margin: 10px;
  transition: all .3s ease;
  flex-direction: column;
}
.prices-container {
  display: flex;
}
/* .villa-title a:hover {
} */
.villa-title a {
  text-decoration: underline;
  color: #000;
  /* text-decoration: none; */
}
.villa-title h1, .villa-title h2 {
  margin: 15px;
  color: #000;
}
.villa-title {
  display: flex;
  display: flex;
  width: 80%;
  margin: 20px auto 0 auto;
  align-items: center;
}
.plattegrond {
  min-width: 60%; 
}

.year-controls {
  text-align: center;
  margin-bottom: 12px;
}
.year-controls span {
  font-size: 27px;
  padding: 8px;
  font-weight: bold;
}
.year-controls a {
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 21px;
  padding: 8px; 
  
}
@media only screen and (max-width: 1000px) {
  /* table.calendar td.month-name {
    position: absolute;
    border-left: none;
    width: 40px;
  } */
  
  .prices-container {
    flex-direction: column;
  }

  .price-calendar {
    width: 100%;
  }
  .villa-page-tabs .react-tabs__tab {
    padding: 10px 12px;
    font-size: 15px;
    margin: 10px 7px 0 0;
  }
  .villa-content .left-content {
    width: 100%;
  }
  .villa-content aside.right-content {
    width: 100%;
  }
  .villa-content {
    flex-direction: column;
  }
  .villa-title h1,
  .villa-title h2 {
    margin: 8px 0;
    padding: 0;
  }
  .villa-title {
    flex-direction: column;
    font-size: 16px;
    align-items: flex-start;
  }
}
.boeken a.button {
  margin: 0 8px;
}
.chateau-list-view.pull-up {
    margin-top: -35px;
}
.chateau-list-view {
    background: url('/images/chateau-bg.png');
    background-size: cover;
    min-height: 60vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    align-items: center;
    z-index: 2
}
.chateau-intro {
    padding: 15px;  
    line-height: 32px;
}

.list.full-width .chateau-item {
    max-width: 28%;
}
.list {
    display: flex;
    flex-direction: row;
    min-width: 67%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
}

.chateau-item:hover {
    box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
}
.chateau-item {
    min-width: 250px;
    margin: 20px;
    max-width: 45%;
    transition: all 0.3s linear 0s;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}
.chateau-item h3 {
    color: rgba(255,255,255,0.7);
    z-index: 292563;
    padding: 5px 0 2px 8px;
    text-transform: uppercase;
    margin:0;
}
.chateau-item figure:hover .caption {
    background: rgba(0,0,0,0.9);
}
.chateau-item figure:hover .caption p {
    opacity: 1;
    height: auto;
    color: rgba(255,255,255,0.7);
}
.chateau-item .caption p {
    color: rgba(255,255,255,0.0);
    margin:0 0 8px 0;
    font-size: 14px;
    text-align: left;
    padding: 0 8px;
    transition: color .4s ease;
    height: 0;
    opacity: 0;
}
.chateau-item .caption {
    transition: all .4s ease;
    background: rgba(0,0,0,0.7);
    position: absolute;
    bottom: 0px;
    margin:0;
    width: 100%;
}
.chateau-item figure {
    position: relative;
    overflow: hidden;
    margin:0;
}
.chateau-item img {
    max-width: 100%;
}


.chateau-page h1 {
    font-size: 48px;
    color: #fff;
    text-shadow: 3px 3px 10px rgba(0,0,0,.6);
}
.chateau-page {
    min-height: 68vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    padding: 0 20px;
}
@media only screen and (max-width: 1000px) {
    .chateau-page h1 { 
        font-size: 44px;
        line-height: 46px;
    }
    .chateau-list-view.pull-up {
    margin-top: 0;
    flex-direction: column-reverse;
    }
    .list {
        padding: 0;
    }

}
.image-gallery-image img {
    transition: all .6s ease;
}
.blur .image-gallery-image img {
    -webkit-filter: blur(5px);  
}
/* 
.image-gallery-thumbnails-wrapper { 
    top: 85px;
} */
.image-gallery-slide-wrapper.top {
    background: rgba(255,255,255,0.8);
    z-index: 40;
}
.image-gallery-slides {
    max-height: 100vh;
}
header figure {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  margin: 0 0 8PX 0;
}
header figure h1 span {
  font-size: 72px;
  letter-spacing: 3.4px;
}
header figure img {
  order: 1;
  width: 26%;
  max-width: 100%;
  margin-right: 15px; 
  margin-left: 15px;
  padding: 0;
}
header figure h1 {
  font-size: 56px;
  letter-spacing: 4.4px;
  font-weight: 400;
  margin: 0 10px;
  order: 2;
  text-transform: uppercase;
  flex-basis: 170px;
}

section.image-intro {
  display: flex;
  /* max-height: 100vh; */
  flex-direction: column;  
  justify-content: center;
  overflow: hidden;
  align-items: center;
}
section.image-intro .image-gallery {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index:0;
  /* max-height: 90vh; */
}

.blur .image-gallery-slide-wrapper {
  width: 100vw;
}
header p {
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
}
header ul {
  display:flex;
  flex-direction: row;
  padding: 0;
  margin: 8px 0 0 0;
  justify-content: space-around;
}
header ul li {
  list-style-type: none;
  font-size: 19px;
  font-weight: 200;
  text-align: center;
  margin: 8px;
}

header.hidden ul, header.hidden p { 
  display:none;
}
header.hidden {
  /* margin-top: 80vh; */
  opacity:0;
  display: none;
}
header {
  transition: opacity .3s ease-in-out;
  background: rgba(255, 255, 255, 1);
  padding: 28px;
  opacity:1;
  position: relative;
  border-radius: 4px;
  z-index: 10;
  flex-direction: column;
  display: flex;
  max-width: 45%;
  margin-top: 35px;
  flex-basis: 43%;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.34);
}
@media only screen and (max-width: 1000px) {
  header ul li {
    font-size: 15px;
    font-weight: 500;
  }
  .image-intro .play{
    display: none;
  }
  .close {
    display: none;
  }
  header figure {
    margin: 0;
  }
  header figure h1 span {
    font-size: 50px;
    letter-spacing: 0px;
  }
  header figure h1 {
    font-size: 33px;
    letter-spacing: 3px;
  }
  section.image-intro .blur .image-gallery {
    display: none;
  }
  section.image-intro .image-gallery {
    margin-top: 50px;
    position: relative;
    background: white;  
  }
  
  section.image-intro {
    background: url('/images/intro-bg.jpg');
    background-size: cover;
    background-position: center;
    max-height: auto;
  }
  header {
    margin-top: 66px;
    margin-bottom: 20px;
    flex-basis: 93%;
    max-width: 90%;
    padding: 10px;
    font-size: 16px;
    line-height: 1.25em;
    text-align: justify;
    letter-spacing: 0.5px;
  }

}
hr {
  margin:12px 5px;
  border: .5px solid;
  border-color: rgba(0,0,0,.57);
}

.container {
  display: flex;
  flex-direction: column;  
}

.image-intro .close:hover {
  -webkit-transform: scale(1.36);
          transform: scale(1.36);
}

.image-intro i {
  z-index: 100;
}

.image-intro .close {
  transition: all .3s ease;
  position: absolute;
  font-size: 27px;
  font-weight: 200;
  right: 0;
  color: rgba(0,0,0, .67);
  top: 0;
  padding: 20px;
}
.image-intro i.play.hidden {
  opacity:0;
  display:none;
}
.image-intro i.play:hover {
  color: rgba(255,255,255,.86);
  
}
.image-intro i.play {
  font-size: 86px;
  color: rgba(255,255,255,.5);
  transition: all .3s ease-in-out;
  margin: 25px;
  position: relative;
  margin-top: 35px;
  text-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.34); 
}
section.image-intro .focus {
  width: 100%;
}
section.image-intro .focus .image-gallery {
  position: relative;
}



nav {
  background: rgba(255,255,255,.9);
  border-top: solid 8px #f5b230;
  width: 100%;
  position: fixed;  
  z-index: 100;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.34);  
}
nav ul {
  margin:0;
  display: inline-block;
}
nav ul li:first-child {
  border:none;
}
nav ul li {
  display: inline;
  padding: 15px 25px;
  line-height: 54px;  /* not sure if needed */
  font-size: 18px;
  border-left: rgba(0,0,0,.86) solid 1px;
  font-family: 'Playfair Display', serif;
}
nav a:hover {
  text-decoration: underline;
}
nav a.active {
  font-weight: bold;
}
nav a {
  color: rgba(0,0,0,1);
  text-decoration: none;
  text-transform: uppercase;
}
nav .brand {
  font-size: 22px;
  font-family: 'Playfair Display', serif;  
  margin-left: 25px;
  display: inline-block;  
  text-transform: uppercase;
}
.button:hover {
  border-bottom: 3px solid #1D5D88;
  border-right: 3px solid #1D5D88;
  background:rgb(86, 107, 121);
}
.button {
  font-size: 16px;
  transition: all .3s ease; 
   height: 40px;
   text-transform: uppercase;
   border: none;  
   border-radius: 4px;
   border-bottom: 3px solid #1D5D88;
   border-right: 3px solid #1D5D88;
   font-weight: 500;
   color: #fff;
   padding: 6px 30px;
   background: #2b87c6;
   display: inline-block;
   line-height: 26px;
   margin: 15px 0;
   text-decoration: none;
   box-sizing: border-box;
   cursor: pointer;
}
.button.yellow:hover {
  border-bottom: 3px solid #b99040;
  border-right: 3px solid #b99040;
  background: #ffac07;
}
.button.yellow {
  background-color: #f5b230;
  border-bottom: 3px solid #b99040;
  border-right: 3px solid #b99040;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;  
}
p {
  line-height: 28px;
}
h1, h2, h3 {
  font-family: 'Playfair Display', serif;
  color: rgba(0,0,0,.86);
}
h1 {
  font-size: 36px;
  line-height: 42px;
}
h1, h2 {
  text-transform: uppercase;
}
.content {
  max-width: 80%;
  min-width: 60%;
  margin: auto;
  font-size: 18px;
  line-height: 24px;
}
.mobileMenu {
  display: none;

}
@media only screen and (max-width: 1000px) {
  h1 {
    font-size: 31px;
    line-height: 33px;
  }
  nav {
    min-height: 50px;
  }
  nav .hamburger {
    display: block;
  }
  nav .brand {
    margin-top: 12px;
  }
  nav ul {
    display: block;
  }
  nav ul li {
    display: block;
    padding: 10px 25px;
    line-height: 22px;
    font-size: 16px;
  }
  .mobileMenu {
    display: block;
    float: right;
    margin: 15px 15px 0px 0px; 
  }
  nav ul {
    display: none;
  }
  nav.menu-open ul {
    padding: 0;
    display: block;
  }
  footer {
    display: none;
  }
  .button{
    font-size: 13px;
    height: 35px;
  }
}
form input:focus,
form textarea:focus
{
    border-color: #f5b230;
    outline: none;
}
form input,
form textarea {
    line-height: 25px;
    background: white;
    font-size: 17px;
    color: black;
    border-width: 2px;
    margin: 12px 0  ;
    border-width: 2px;
    border-style: solid;  
    height: 35px;
    width: 100%;
    padding: 5px 10px;
    box-sizing: border-box;
}
form input:focus::-webkit-input-placeholder {
    color: rgb(224, 224, 224);    
}
form input::-webkit-input-placeholder {
    color: rgb(73, 73, 73);
}

form textarea {
    min-height: 150px;
}
.social-medial {
    width: 100%;
}
.contact-page {
    display: flex;
    padding-top:10px;
    align-items: center;
    margin: 0 auto;
    max-width: 85%; 
    margin-top:60px;
}
.contact-forms {
    min-width: 60%;
    margin-right: 25px;
}
.contact-form-type {
    margin-bottom: 8px;
}
.personal-contact img {
    border: 12px solid #2c87c6;
}
.personal-contact {
    font-size: 16px;
    font-weight: 100;
}
.contact-form-type label input {
    margin-right: 8px;
}
.contact-form-type label {
    margin: 15px;
    font-size: 18px;
    text-transform: uppercase;
}
.react-datepicker__input-container {
    width: 70%;
}
.contact-page .villa-field label{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
}
.contact-page .villa-field label input{
    width: auto; 
    margin: 12px;
    display: inline-block;
    font-size: 21px;
}
@media only screen and (max-width: 1000px) {
    
.contact-form-type label {
    font-size: 15px;
    }

.contact-form-type {
    margin-right: 46px;
    }
.contact-page{
    flex-direction: column;
}
.personal-contact img {
    max-width: 95%;
}
h1 {
    text-align: center;
}

}

