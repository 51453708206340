@import '../../../node_modules/react-tabs/style/react-tabs.css';

.villa-compact-view span.prijs span {
    font-size: 17px;
}
.villa-compact-view span.prijs {
    font-size: 28px;
    margin-left: 8px;
}
.villa-compact-view .button{
    align-self: right;
}	
.villa-compact-view {
    background: url('/images/villas-bg.jpg');
    background-size: cover;
    background-position: center;
    min-height: 60vh;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}
.villa-compact-view .react-tabs .react-tabs__tab-panel {
    background: #fff;
    box-shadow: 10px 10px 63px 0px rgba(0,0,0,0.45);
    padding: 20px;
    z-index: 1;
    position: relative;
    font-family: 'Montserrat', sans-serif;
    line-height: 32px;
    border-radius: 0;
}
.villa-compact-view .react-tabs .react-tabs__tab-list {
    margin: 0;
}
.villa-compact-view .react-tabs {
    width: 900px;
    margin: 100px auto;
}
.villa-compact-view .react-tabs .react-tabs__tab.react-tabs__tab--selected {
    position: relative;
    z-index: 2;
    opacity: 1;
    color: rgba(0,0,0,.87);
    background: white;
}
.villa-compact-view .react-tabs .react-tabs__tab {
    height: auto;
    transition: all .6s ease;
    box-shadow: 6px -11px 28px -4px rgba(0,0,0,0.45);
    color: white;
    font-size: 24px;
    padding: 10px 30px;
    background: #323230;
    font-family: 'Playfair Display', serif;
    text-transform: uppercase;
    border-radius:0;
    border: none;
}
.villa-compact-view .react-tabs__tab .villa-actions {
    display: flex;
    flex-direction: row;
}
section.villa-page-tabs {
    padding: 0 5%;
}
.villa-page-tabs .react-tabs__tab.react-tabs__tab--selected {
    background: #f5b230;
    border: none;
    border-radius: 0;
}
.villa-page-tabs .react-tabs__tab-list {
    padding-bottom: 1px;
    border-bottom: 2px solid #f5b230;
}
.villa-page-tabs .react-tabs__tab {
    border: none;
    background: #f2daab;
    text-transform: uppercase;
    color: #fff;
    padding: 14px 40px;
    font-size: 18px;
    margin: 0 7px 0 0;
}
.villa-page-tabs h1 {
    font-size: 48px;
    margin: 45px 8px 25px 8px;
}
.villa-content {
  display: flex;
  flex-direction: row;
}
.villa-content aside h1,
.villa-content aside h2,
.villa-content aside h3,
.villa-content aside p,
.villa-content hr,
.villa-content aside li {
  color: #fff;
  font-weight: 100;
  border-color: #fff;
}
.villa-content aside {
  background: #323331;
  color: #fff;
  box-sizing: border-box;
  padding: 15px;
  line-height: 28px;
}
.villa-content .left-content {
  width: 80%;
}
.villa-content aside.right-content {
  color: #fff;
  width: 390px;
}
.price-calendar {
  position: relative;
  width: 90%;
}
table.calendar {
  border-collapse: collapse;
  width: 100%;
}

table.calendar thead {
  background-color: #323331;
  color: white;
  margin-bottom: 3px;
  border-bottom: 2px solid white
}


table.calendar thead th {
  font-weight: normal;
  padding: 10px 3px;
}

table.calendar thead th.bolder {
  font-weight: bold;
}

table.calendar tbody {
  font-size: 0.8em;
}

table.calendar td {
  text-align: center;
  padding: 8px;
  cursor: pointer;
  border: 1px solid rgba(185, 185, 185, 0.13);
  background-color: white;
  min-width: 15px;
}

table.calendar tr:last-child td {
  border-bottom: none;
}

table.calendar td.month-name {
  font-weight: bold;
  text-align: left;
  cursor: default;
  border-left: none;
}

table.calendar td.prev-month,
table.calendar td.next-month {
  color: transparent;
  cursor: default;
  pointer-events: none;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=');
}

table.calendar td.week-separator {
  pointer-events: none;
  padding: 0;
  width: 8px;
  min-width: 0;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=');
}

table.calendar td.bolder {
  font-weight: bold;
}

/* Single selected day */
table.calendar td.selected {
  background-color: #b22b26;
  color: white;
  font-weight: bold;
}

/* Selected range */
table.calendar td.range {
  background-color: #2b87c6;
  font-weight: bold;
  color: white;
}

table.calendar td.range-left {
  /* border-top-left-radius: 15px;
    border-bottom-left-radius: 15px; */
    overflow: hidden;
    background: #2b87c6;
  }
  
  table.calendar td.range-right {
    /* border-top-right-radius: 15px;
    border-bottom-right-radius: 15px; */
    overflow: hidden;
    background: #2b87c6;
  }
  
  div.calendar-controls {
    margin: 5px auto;
    display: table;
    font-size: 25px;
    line-height: 35px;
  }
  
  div.calendar-controls div {
    display: inline;
  }
  
  div.calendar-controls .current-year {
    margin: 0 30px;
  }
  
  div.calendar-controls .control {
    font-weight: bolder;
    color: #323232;
    font-size: 0.8em;
    cursor: pointer;
  }
  
  div.calendar-controls .today {
    position: absolute;
    right: 15px;
    line-height: 35px;
    font-size: 0.6em;
    text-transform: uppercase;
  }
  
  /* Demo */
  
  div#calendar {
    position: relative;
    border-radius: 5px;
    border: 1px solid #5A5A5A;
    background-color: white;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  section.prijzen {
    background: url('/images/uitzicht-bg.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .calendar-container {
    max-width: 100%;
    margin:0;
    overflow-x: auto;
  }
  table.calendar td.lastminute {
    background: #f7cf81;
    font-weight: bold;
    border: 2px dashed #f3a921;
    color: rgb(211, 16, 16);

  }
  table.calendar td.blocked.range-right.changedayStart {
    background-color: #2b87c6;
  }
  table.calendar td.blocked.range-left.changedayEnd {
    background-color: #2b87c6;
  }
  table.calendar td.blocked.changedayEnd {
    background-image: url('/images/change-day.svg') !important;
    background-size: cover;
    background-color: white;
    border:none;
  }
  table.calendar td.blocked.changedayStart {
    background-image: url('/images/change-day-2.svg') !important;
    background-size: cover;
    background-color: white;
    border:none;
  }
  table.calendar td.blocked {
    background: orangered;
    border: none;
  }
  aside.legenda {
    background: white;
    padding: 5px;
    margin-top: -10px;
    margin-right: -10px;
    line-height: 25px;
  }
  .legenda-item.lastminute {
    color: rgb(211, 16, 16);
  }
  .legenda-item.blocked:before {
    background-color: orangered
  }
  .legenda-item.lastminute:before {
    background: #f7cf81;
    font-weight: bold;
    border: 2px dashed #f3a921;
  }
  .legenda-item {
    margin-left: 50px;
  }
  .legenda-item.available:before {
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=');
  }
  .legenda-item:before {
    content: ' ';
    position: relative;
    left: -15px;
    top: 1px;
    height: 15px;
    width: 15px;
    background: white;
    display: inline-block;
    box-shadow: 0 3px 14px rgba(0,0,0,.46);
  }
section.prijs-indicatie h2 {
  margin-top: 5px;
  color: white; 
}
section.prijs-indicatie p {
  color: rgba(255,255,255,.86);
}
section.prijs-indicatie {
  background: #323331;
  padding: 10px;
}
ul.prices {
  display: flex;
  width: 100%;
  padding:0;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.prices li.prijs .prijs-tot span,
.prices li.prijs .prijs-vanaf span {
  font-weight: bold;
}
.prices li.prijs .prijs-vanaf {
  padding: 6px 24px 6px 6px;
  background: rgba(0,0,0,.046);
  margin: 8px 0 0 0;
}
.prices li.prijs .prijs-price h3 {
text-transform: uppercase;
}
.prices li.prijs .prijs-tot {
  padding: 6px 24px 6px 6px;
  border-top: 1px solid rgba(0,0,0,.146);
  background: rgba(0,0,0,.046);
  margin: 0 0 8px 0;
}
.prices li.prijs h3 {
  margin: 0 0 8px 0;
  text-transform: uppercase;
}
.prices li.prijs h3 i {
  padding: 0 5px;
}
.prices li.prijs {
  display: flex;
  padding: 10px;
  background: white;
  box-shadow: 2px 0 12px rgba(0,0,0, 0.8);
  width: 90%;
  margin: 10px;
  transition: all .3s ease;
  flex-direction: column;
}
.prices-container {
  display: flex;
}
/* .villa-title a:hover {
} */
.villa-title a {
  text-decoration: underline;
  color: #000;
  /* text-decoration: none; */
}
.villa-title h1, .villa-title h2 {
  margin: 15px;
  color: #000;
}
.villa-title {
  display: flex;
  display: flex;
  width: 80%;
  margin: 20px auto 0 auto;
  align-items: center;
}
.plattegrond {
  min-width: 60%; 
}

.year-controls {
  text-align: center;
  margin-bottom: 12px;
}
.year-controls span {
  font-size: 27px;
  padding: 8px;
  font-weight: bold;
}
.year-controls a {
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 21px;
  padding: 8px; 
  
}
@media only screen and (max-width: 1000px) {
  /* table.calendar td.month-name {
    position: absolute;
    border-left: none;
    width: 40px;
  } */
  
  .prices-container {
    flex-direction: column;
  }

  .price-calendar {
    width: 100%;
  }
  .villa-page-tabs .react-tabs__tab {
    padding: 10px 12px;
    font-size: 15px;
    margin: 10px 7px 0 0;
  }
  .villa-content .left-content {
    width: 100%;
  }
  .villa-content aside.right-content {
    width: 100%;
  }
  .villa-content {
    flex-direction: column;
  }
  .villa-title h1,
  .villa-title h2 {
    margin: 8px 0;
    padding: 0;
  }
  .villa-title {
    flex-direction: column;
    font-size: 16px;
    align-items: flex-start;
  }
}
.boeken a.button {
  margin: 0 8px;
}